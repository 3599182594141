














import MenuMixin from '@/mixins/menu-mixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class Task extends mixins(MenuMixin) {
  menu = [
    {
      title: '模板配置中心',
      icon: 'appstore',
      children: [
        { title: '配置工作台', key: '/setting/send', icon: 'unordered-list' },
        { title: '审批工作台', key: '/setting/review', icon: 'unordered-list' },
        { title: '结算模型管理', key: '/setting/model', icon: 'unordered-list' },
        { title: '接口管理', key: '/setting/interface', icon: 'unordered-list' },
        { title: '字典集管理', key: '/setting/dict', icon: 'unordered-list' }
      ]
    }
  ];

  created() {}
}
